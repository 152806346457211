import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { OrderHistory } from 'domains/profile/containers/order-history';

const TITLE = 'Order History';

const ProfileOrderHistoryPage: ApplicationPage = () => {
  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{ title: TITLE, defaultBackRoute: Routes.Profile }}
    >
      <OrderHistory />
    </Page>
  );
};

ProfileOrderHistoryPage.getMeta = () => ({ title: TITLE });

ProfileOrderHistoryPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileOrderHistoryPage;

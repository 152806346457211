import { Hero } from '@swe/shared/ui-kit/components/hero';
import { CartIcon } from '@swe/shared/ui-kit/components/icon';
import { CartIllustration } from '@swe/shared/ui-kit/components/illustration';
import Loader from '@swe/shared/ui-kit/components/loader';
import { Pagination } from '@swe/shared/ui-kit/components/pagination';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback } from 'react';

import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { OrderCard } from 'domains/profile/components/order-card';
import { useOrdersHistory } from 'domains/profile/use-cases/use-orders-history';

const OrderHistory = () => {
  const { orders, isLoading, pagination, total, pagesCount, onPaginationChange, buildPageLink } = useOrdersHistory();
  const { toCatalog: _toCatalog } = useCatalogQuery();

  const toCatalog = useCallback(() => {
    void _toCatalog({ filters: null, subPath: null, searchTerm: null });
  }, [_toCatalog]);

  if (isLoading && !orders) {
    return (
      <Loader
        size="lg"
        centered
      />
    );
  }

  return (
    <Stack
      spacing={{
        mobile: 'xs',
        desktop: 'lg',
        tablet: 'lg',
      }}
    >
      {!isLoading && !orders?.list.length && (
        <Hero
          title="There are no orders yet"
          illustration={<CartIllustration />}
          actions={[
            {
              children: 'Shop Now',
              icon: CartIcon,
              name: 'shop-now',
              onClick: toCatalog,
              block: true,
            },
          ]}
        />
      )}
      {!!orders?.list.length && (
        <Stack>
          {orders?.list.map((order) => (
            <OrderCard
              key={order.id}
              order={order}
            />
          ))}
          {pagesCount > 1 && (
            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.pageSize}
              total={total}
              buildHref={buildPageLink}
              onChange={onPaginationChange}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export { OrderHistory };
